$(document).ready(function(){

    $('.owl-carousel').owlCarousel({
        items: 1,
        autoplay: true,
        autoplayHoverPause: true,
        loop: true,
        center: true,
        onChange: function () {
            updateSize();
        },
        onInitialize:function(){
            updateSize();
        }
    });

    function updateSize(){
        var minHeight=parseInt($('.owl-item').eq(0).css('height'));
        $('.owl-item').each(function () {
            var thisHeight = parseInt($(this).css('height'));
            minHeight=(minHeight<=thisHeight?minHeight:thisHeight);
        });
        $('.owl-wrapper-outer').css('height',minHeight+'px');
    }

    var $carouselTarget = $('.carousel-target').find('img');
    var $carouselItems = $('.carousel-item');
    $carouselItems.each(function() {
        $(this).click(function(){

            var src = $(this).find('img').attr('src');
            $carouselTarget.attr('src', src);
        });
    });

});