$(document).ready(function(){

    /* The selector to trigger the popups on */
    var sharePops = $('.work-share .pop');

    $.each(sharePops, function(index, target) {
        $(target).on('click', function(event) {
            event.preventDefault();
            var left = (window.screen ? Math.round(screen.width / 2 - 275) : 100);
            window.open(
                target.href,
                "sharePop",
                'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420,top=100,left=' + left
            );
        });
    });

});